import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ReservationsActiveModelsStoreEffects } from './effects';
import { reservationsActiveModelsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'reservationsActiveModels',
      reservationsActiveModelsReducer,
    ),
    EffectsModule.forFeature([ReservationsActiveModelsStoreEffects]),
  ],
})
export class ReservationsActiveModelsStoreModule {}
