import { Action, createReducer, on } from '@ngrx/store';
import { get } from 'lodash';
import moment from 'moment';

import {
  ReservationActive,
  ReservationActiveAccommodation,
  ReservationActiveGuest,
} from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) => {
    const newItems = buildReservationActiveData(items);
    return fromState.featureAdapter.setAll(newItems, {
      ...state,
      isLoading: false,
      error: null,
    });
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function reservationsActiveModelsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function buildReservationActiveData(reservations): ReservationActive[] {
  return reservations.map((reservation) => {
    const { id, booker, arrival_date, departure_date, accommodations } =
      reservation;
    const { name, surname } = booker;
    return {
      id,
      label: name + ' ' + surname,
      arrival_date,
      departure_date,
      accommodations: buildAccommodation(accommodations, booker),
    };
  });
}

function buildAccommodation(
  accommodations = [],
  booker,
): ReservationActiveAccommodation[] {
  return accommodations
    .filter((_accommodations) =>
      get(_accommodations, 'rooms[0]') ? true : false,
    )
    .map((accommodation) => {
      const {
        id: reservation_accommodation_id,
        rooms,
        my_accommodation: { name },
        guests,
      } = accommodation;
      const { arrival_date, departure_date, my_tableau } = rooms[0];
      let label = 'OB';
      if (my_tableau && my_tableau.label) {
        label = my_tableau.label;
      }
      return {
        name,
        reservation_accommodation_id,
        tableauNumber: label,
        arrival_date,
        departure_date,
        guests: buildGuest(guests, booker),
      };
    });
}

function buildGuest(guests = [], booker): ReservationActiveGuest[] {
  if (!guests.length) {
    const { name, surname, id: customer_id } = booker;
    return [
      {
        customer_id,
        fullName: name + ' ' + surname,
      },
    ];
  }
  return guests.map((guest) => {
    const {
      customer: { id: customer_id, name, surname },
    } = guest;
    return {
      customer_id,
      fullName: name + ' ' + surname,
    };
  });
}
