import { createAction, props } from '@ngrx/store';

import { ReservationActive } from '../../models';

export const loadRequest = createAction(
  '[Reservation Active] Load Request',
  props<{ propertyIds: number[]; now: string }>(),
);

export const loadSuccess = createAction(
  '[Reservation Active] Load Success',
  props<{ items: ReservationActive[] }>(),
);

export const loadFailure = createAction(
  '[Reservation Active] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Reservation Active] Reset State');
